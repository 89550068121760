const barcodeRead = {
  code: "",
  reading: false,
  event: null,
  listener(e) {
    //usually scanners throw an 'Enter' key at the end of read
    if (e.key === "Enter") {
      if (barcodeRead.code.length > 2) {
        barcodeRead.event(barcodeRead.code);
      }
      barcodeRead.code = "";
      barcodeRead.reading = false;
    } else {
      barcodeRead.code += e.key; //while this is not an 'enter' it stores the every key
    }

    //run a timeout of 800ms at the first read and clear everything
    if (!barcodeRead.reading) {
      barcodeRead.reading = true;
      setTimeout(() => {
        if (barcodeRead.code.length > 5 && barcodeRead.code.length < 15) {
          barcodeRead.event(barcodeRead.code);
          barcodeRead.code = "";
          barcodeRead.reading = false;
        }
        barcodeRead.code = "";
        barcodeRead.reading = false;
      }, 150);
    }
  },

  textinput(event) {
    if (
      event.target.tagName === "INPUT" ||
      event.target.tagName === "TEXTAREA" ||
      event.target.type === "text"
    ) {
      return;
    }
    if (event.data.length > 2) {
      barcodeRead.event(event.data);
      //event.preventDefault();
    }
  },

  clipboard(event) {
    let paste = (event.clipboardData || window.clipboardData).getData("text");
    if (
      event.target.tagName === "INPUT" ||
      event.target.tagName === "TEXTAREA" ||
      event.target.type === "text"
    ) {
      return;
    }
    if (paste.length > 2 && paste.length < 15) {
      barcodeRead.event(paste);
    }
  },

  unload() {
    document.removeEventListener("keypress", this.listener);
    document.removeEventListener("paste", this.clipboard);
    document.removeEventListener("textInput", this.textinput);
  },

  load(event) {
    this.event = event;
    document.addEventListener("keypress", this.listener);
    document.addEventListener("paste", this.clipboard);
    document.addEventListener("textInput", this.textinput);
  },
};

export default barcodeRead;

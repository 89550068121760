import i18n from "../i18n";

const ItemOperationTypes = {
  1: {
    name: i18n.global.t("itemOperationTypes.betweenWarehouses"),
    color: "badge-outline",
    id: 0,
    icon: "tir",
    type: "transfer",
  },
  2: {
    name: i18n.global.t("itemOperationTypes.productIncome"),
    color: "successful text-white",
    id: 2,
    icon: "tir",
    type: "income",
  },
  3: {
    name: i18n.global.t("itemOperationTypes.productOutcome"),
    color: "info text-white",
    id: 3,
    icon: "delivery-info",
    type: "outcome",
  },
  4: {
    name: i18n.global.t("itemOperationTypes.inventoryDeficit"),
    color: "bg-danger text-white",
    id: 4,
    icon: "delivery-info",
    type: "outcome",
  },
  5: {
    name: i18n.global.t("itemOperationTypes.materialIncome"),
    color: "light text-dark",
    id: 3,
    icon: "delivery-info",
    type: "income",
  },
  6: {
    name: i18n.global.t("itemOperationTypes.production"),
    color: "dark text-white",
    id: 6,
    icon: "delivery-info",
    type: "income",
  },
  7: {
    name: i18n.global.t("itemOperationTypes.inventoryWaste"),
    color: "dark text-white",
    id: 7,
    icon: "delivery-info",
    type: "outcome",
  },
  8: {
    name: i18n.global.t("itemOperationTypes.repair"),
    color: "dark text-white",
    id: 8,
    icon: "delivery-info",
    type: "outcome",
  },
};

export default ItemOperationTypes;

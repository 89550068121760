import i18n from "../i18n";

const incomingItemsStatuses = {
  "-3": {
    name: i18n.global.t("incomingItemsStatuses.waitForStart"),
    color: "bg-light text-body",
    id: -3,
  },
  "-2": {
    name: i18n.global.t("incomingItemsStatuses.waitForMaterials"),
    color: "bg-light text-body",
    id: -2,
  },
  "-1": {
    name: i18n.global.t("incomingItemsStatuses.waitForProduction"),
    color: "bg-light text-body",
    id: -1,
  },
  0: {
    name: i18n.global.t("incomingItemsStatuses.underProduction"),
    color: "bg-info text-body",
    id: 0,
  },
  1: {
    name: i18n.global.t("incomingItemsStatuses.completed"),
    color: "bg-secondary text-white",
    id: 1,
  },
  2: {
    name: i18n.global.t("incomingItemsStatuses.delivered"),
    color: "bg-success text-white",
    id: 2,
  },
  3: {
    name: i18n.global.t("incomingItemsStatuses.cancelled"),
    color: "bg-danger text-white",
    id: 3,
  },
};

export default incomingItemsStatuses;
